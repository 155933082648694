import React from 'react';
import ReactDOM from 'react-dom';
import { AwsUi } from '@amzn/awsui-components-react/polaris/runtime';

import configureAmplify from './configureAmplify';
import App from './App';

// Modify the config.json to your own Gandalf values.
import configService from './services/config';

AwsUi.config({
  production: process.env.NODE_ENV !== 'development',
  logLevel: 3,
});

// Change this boolean to control if Gandalf Custom UI should be used.
const useCustomSignInUI = true;

Promise.resolve()
  .then(configService.init)
  .then((config) => {
    configureAmplify({ config, useCustomSignInUI });

    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
