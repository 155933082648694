import React, { useState, useEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import Button from '@amzn/awsui-components-react/polaris/button';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import FormSection from '@amzn/awsui-components-react/polaris/form-section';

import Auth from '@aws-amplify/auth';
import './App.css';

import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import Container from './components/Container';

const getUserAttr = (user: any, attr: string) =>
  user?.signInUserSession?.idToken?.payload?.[attr];

// To make it easier to see all usage flows all logic has been put into one component.
const App = () => {
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [showError, setShowError] = useState(false);

  // Setup Amplify Hub listener to receive auth events.
  useEffect(() => {
    getUser().then((userData) => setUser(userData));
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('Sign in failure', data);
          setShowError(true);
          break;
      }
    });
  }, []);

  const getUser = () =>
    Auth.currentAuthenticatedUser().catch(() => {
      console.log('Not signed in');
      setShowLogin(true);
    });

  const handleSignIn = () => Auth.federatedSignIn();

  var currentTime = new Date().toUTCString();

  var body;
  var head;
  if (showError) {
    head = <h2>SSO authentication failed</h2>;
    body = (
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <p> An error occurred, please try again later. </p>
          <p> Error Time: {currentTime} </p>
        </div>
      </ColumnLayout>
    );
  } else if (!user && showLogin) {
    head = <h2>SSO authentication handshake</h2>;
    body = (
      <ColumnLayout>
        <div data-awsui-column-layout-root="true">
          <p>Please login to see your credentials.</p>
          <div>
            <span className="awsui-util-f-r">
              <Button
                data-testid="signin-button"
                onClick={handleSignIn}
                formAction="none"
              >
                Login
              </Button>
            </span>
          </div>
        </div>
      </ColumnLayout>
    );
  } else if (user) {
    head = <h2>SSO authentication handshake successful</h2>;
    if (
      typeof getUserAttr(user, 'name') == 'string' &&
      getUserAttr(user, 'name').length > 0 &&
      typeof getUserAttr(user, 'email') == 'string' &&
      getUserAttr(user, 'email').length > 0
    ) {
      body = (
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            <p>
              Welcome:&nbsp;
              {getUserAttr(user, 'name')}
            </p>
            <p>
              Name:&nbsp;
              {getUserAttr(user, 'name')}
            </p>
            <p>
              EmailAddress:&nbsp;
              {getUserAttr(user, 'email')}
            </p>
          </div>
        </ColumnLayout>
      );
    } else {
      head = (
        <h2>
          SSO authentication handshake unsuccessful with missing attributes
        </h2>
      );
      body = (
        <ColumnLayout>
          <div data-awsui-column-layout-root="true">
            <p>
              Name:&nbsp;
              {getUserAttr(user, 'name') || '<missing>'}
            </p>
            <p>
              EmailAddress:&nbsp;
              {getUserAttr(user, 'email') || '<missing>'}
            </p>
          </div>
        </ColumnLayout>
      );
    }
  }
  return (
    <div className="App">
      <Header />
      <Main>
        <Container>
          <FormSection header={<h1 title="heading"> {head}</h1>}>
            {body}
          </FormSection>
        </Container>
      </Main>
      <Footer />
    </div>
  );
};

export default App;
