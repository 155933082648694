import { isDevelopment } from '../utils/env';
import { retry } from '../utils/promise';

const CONFIG_URI = '/config/config.json';

export interface AppConfig {
  Auth: {
    [key: string]: string[];
  };
  Gandalf: {
    [key: string]: string[];
  };
}

let cachedConfig: AppConfig;

const init = async (): Promise<AppConfig> => {
  try {
    if (isDevelopment) {
      const response = await retry(() => import('../devconfig/config.json'));
      return (cachedConfig = response.default);
    } else {
      console.log('getting xxx config.json...');
      const response = await retry(() => fetch(CONFIG_URI), {
        retries: 3,
        interval: 100,
      });
      console.log('got xxx config.json..');
      return (cachedConfig = await response.json());
    }
  } catch (err) {
    let msg = 'Failed to fetch config';
    if (err instanceof Error) msg += `: ${err.message}`;
    throw new Error(msg);
  }
};

const get = (): AppConfig => {
  if (!cachedConfig) throw new Error('Need to run init.');
  return cachedConfig;
};

export default {
  init,
  get,
};
