import Amplify from '@aws-amplify/core';

// Enable Amplify debugging for better insights when doing local dev.
(window as any).LOG_LEVEL = 'DEBUG';

// Creates a urlOpener function to intercept sign in calls and change the url.
// See docs, https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html
const createUrlInterceptor =
  (domain: string, signInUIDomain: string) => (url: string) => {
    const newUrl = generateNewUrl(domain, signInUIDomain, url);
    const windowProxy = window.open(newUrl, '_self');
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
  };

function generateNewUrl(
  domain: string,
  signInUIDomain: string,
  url: string
): string {
  const loginRequestUrl = `https://${domain}/oauth2/authorize`;
  let newUrl = url;
  if (signInUIDomain && url.startsWith(loginRequestUrl)) {
    newUrl = url.replace(loginRequestUrl, `https://${signInUIDomain}/login`);
  }
  return addUrlParameters(newUrl);
}

function addUrlParameters(url: string): string {
  const urlObj = new URL(url);
  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => urlObj.searchParams.append(key, value));
  return urlObj.toString();
}

const configureAmplify = ({
  config,
  useCustomSignInUI,
}: {
  config: any;
  useCustomSignInUI: boolean;
}) => {
  return Amplify.configure({
    Auth: {
      region: config.Auth.region,
      userPoolId: config.Auth.userPoolId,
      userPoolWebClientId: config.Auth.userPoolWebClientId,
      oauth: {
        domain: config.Auth.oauth.domain,
        scope: config.Auth.oauth.scope,
        responseType: config.Auth.oauth.responseType,
        redirectSignIn: config.Auth.oauth.redirectSignIn,
        redirectSignOut: config.Auth.oauth.redirectSignOut,
        // (optional) This is used to intercept login requests to navigate to the Custom SignIn UI.
        ...(useCustomSignInUI && {
          urlOpener: createUrlInterceptor(
            config.Auth.oauth.domain,
            config.Gandalf.customSignInDomain
          ),
        }),
      },
    },
  });
};

export default configureAmplify;
