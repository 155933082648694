import React from 'react';

import styles from './Footer.module.css';

const whatYearIsIt = new Date().getFullYear();

const Footer = () => (
  <footer className={styles.footer} role="contentinfo">
    <a
      className={styles.item}
      href="https://aws.amazon.com/privacy/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy
    </a>
    <span aria-hidden="true">|</span>
    <a
      className={styles.item}
      href="https://aws.amazon.com/terms/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Site terms
    </a>
    <span aria-hidden="true">|</span>
    <span className={styles.item}>
      {`© ${whatYearIsIt}, Amazon Web Services, Inc. or its affiliates. All rights reserved.`}
    </span>
  </footer>
);

export default Footer;
