import React from 'react';

import logo from '../assets/logo-color-dark.svg';
import './Header.css';

const Header = () => {
  return (
    <header className="Header">
      <div className="Header__branding">
        <img src={logo} alt="AWS Training and Certification" />
      </div>
    </header>
  );
};

export default Header;
